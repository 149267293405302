import styled from '@emotion/styled'
import tw from 'twin.macro'

export const Wrapper = styled('aside')`
  ${tw`my-8 px-6 py-4`}
  ${tw`flex flex-col sm:flex-row gap-6`}
  ${tw`border-grey-300 border border-l-4 rounded`}
`

export const Body = styled('div')`
  ${tw`flex-grow`}
  > *:not(.sr-only) + * {
    ${tw`mt-6`}
  }
`

export const IconContainer = styled('div')`
  ${tw`flex-grow content-start mr-6`}

  > div {
    ${tw`mx-0`}
  }
`
