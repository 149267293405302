import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Field, Row, YourPretSignUpContainer, Hr } from '../styles'
import { Checkbox, Dropdown, Input } from '@pretamanger/component-library'
import { useChecked, useInput } from '../../../hooks'

const FORM = 'yourpret-signup'
const FIRST_NAME = 'first-name'
const LAST_NAME = 'last-name'
const EMAIL = 'email-address'
const CONFIRM_EMAIL = 'confirm-email-address'
const PHONE = 'telephone-number'
const TERMS = 'terms-and-conditions'
const FREQUENCY = 'frequency'
const FEEDBACK = 'feedback'
const MARKETING = 'marketing'

const formDefinition = {
  id: FORM,
  fields: [
    { id: FIRST_NAME, type: ['isNotEmpty'] },
    { id: LAST_NAME, type: ['isNotEmpty'] },
    { id: EMAIL, type: ['isNotEmpty', 'isEmail'] },
    { id: CONFIRM_EMAIL, type: ['isNotEmpty', 'isEmail'] },
    { id: PHONE, type: ['isNotEmpty', 'isNumeric'] },
    { id: FREQUENCY, type: ['isNotEmpty'] },
    { id: TERMS, type: ['isTrue'] },
    { id: FEEDBACK, type: [] },
    { id: MARKETING, type: [] }
  ]
}

type FormState =
  | {
      'first-name'?: string
      'last-name'?: string
      'email-address'?: string
      'confirm-email-address'?: string
      'telephone-number'?: string
      'terms-and-conditions'?: boolean
      frequency?: string
      feedback?: boolean
      marketing?: boolean
    }
  | {}

type GetProps = (
  id: string,
  dirty: boolean,
  isValid: boolean,
  options?: any
) => any

const SignUp = ({
  formState,
  getOptions,
  getProps
}: {
  formState: FormState
  getOptions: (id: string) => any[]
  getProps: GetProps
}) => {
  function createInitialState(id) {
    return {
      ...(formState[id] ? formState[id] : { id, dirty: false, isValid: true }),
      formId: FORM,
      form: formDefinition
    }
  }
  const [termsChecked, setTermsChecked] = useChecked()
  const [feedbackChecked, setFeedbackChecked] = useChecked()
  const [marketingChecked, setMarketingChecked] = useChecked()

  const {
    fieldState: firstName,
    setFieldState: setFirstName,
    bind: bindFirstName
  } = useInput(createInitialState(FIRST_NAME))
  const {
    fieldState: lastName,
    setFieldState: setLastName,
    bind: bindLastName
  } = useInput(createInitialState(LAST_NAME))
  const {
    fieldState: emailAddress,
    setFieldState: setEmailAddress,
    bind: bindEmailAddress
  } = useInput(createInitialState(EMAIL))
  const {
    fieldState: confirmEmailAddress,
    setFieldState: setConfirmEmailAddress,
    bind: bindConfirmEmailAddress
  } = useInput(createInitialState(CONFIRM_EMAIL))
  const {
    fieldState: telephoneNumber,
    setFieldState: setTelephoneNumber,
    bind: bindTelephoneNumber
  } = useInput(createInitialState(PHONE))
  const {
    fieldState: terms,
    setFieldState: setTerms,
    bind: bindTerms
  } = useInput(createInitialState(TERMS))
  const {
    fieldState: frequency,
    setFieldState: setFrequency,
    bind: bindFrequency
  } = useInput(createInitialState(FREQUENCY))
  const {
    fieldState: feedback,
    setFieldState: setFeedback,
    bind: bindFeedback
  } = useInput(createInitialState(FEEDBACK))
  const {
    fieldState: marketing,
    setFieldState: setMarketing,
    bind: bindMarketing
  } = useInput(createInitialState(MARKETING))

  useEffect(() => {
    formState[FIRST_NAME] && setFirstName(formState[FIRST_NAME])
    formState[LAST_NAME] && setLastName(formState[LAST_NAME])
    formState[EMAIL] && setEmailAddress(formState[EMAIL])
    formState[CONFIRM_EMAIL] && setConfirmEmailAddress(formState[CONFIRM_EMAIL])
    formState[PHONE] && setTelephoneNumber(formState[PHONE])
    formState[TERMS] && setTerms(formState[TERMS])
    formState[FREQUENCY] && setFrequency(formState[FREQUENCY])
    formState[FEEDBACK] && setFeedback(formState[FEEDBACK])
    formState[MARKETING] && setMarketing(formState[MARKETING])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState])

  const configureField = (id, field, bind, options?) => ({
    ...getProps(id, field.dirty, field.isValid, options),
    ...bind
  })

  return (
    <YourPretSignUpContainer>
      <Row>
        <Field>
          <Input {...configureField(FIRST_NAME, firstName, bindFirstName)} />
        </Field>
      </Row>
      <Row>
        <Field>
          <Input {...configureField(LAST_NAME, lastName, bindLastName)} />
        </Field>
      </Row>
      <Row>
        <Field fullWidth>
          <Input {...configureField(EMAIL, emailAddress, bindEmailAddress)} />
        </Field>
      </Row>
      <Row>
        <Field fullWidth>
          <Input
            {...configureField(
              CONFIRM_EMAIL,
              confirmEmailAddress,
              bindConfirmEmailAddress
            )}
          />
        </Field>
      </Row>
      <Row>
        <Field fullWidth>
          <Input
            {...configureField(PHONE, telephoneNumber, bindTelephoneNumber)}
          />
        </Field>
      </Row>
      <Row>
        <Hr />
      </Row>
      <Row>
        <Field>
          <Checkbox
            {...configureField(MARKETING, marketing, bindMarketing)}
            onChange={setMarketingChecked}
            isSelected={marketingChecked}
          />
        </Field>
      </Row>
      <Row>
        <Field>
          <Dropdown
            {...configureField(FREQUENCY, frequency, bindFrequency)}
            options={getOptions(FREQUENCY)}
          />
        </Field>
      </Row>
      <Row>
        <Field>
          <Checkbox
            {...configureField(FEEDBACK, feedback, bindFeedback)}
            onChange={setFeedbackChecked}
            isSelected={feedbackChecked}
          />
        </Field>
      </Row>
      <Row>
        <Field>
          <Checkbox
            {...configureField(TERMS, terms, bindTerms, {
              isRichTextLabel: true
            })}
            onChange={setTermsChecked}
            isSelected={termsChecked}
          />
        </Field>
      </Row>
    </YourPretSignUpContainer>
  )
}

SignUp.formDefinition = formDefinition

SignUp.propTypes = {
  formState: PropTypes.object.isRequired,
  getOptions: PropTypes.func.isRequired,
  getProps: PropTypes.func.isRequired
}

export default SignUp
