import PropTypes from 'prop-types'
import { Dropdown } from '@pretamanger/component-library'
import { Field, Row, YourPretSignUpContainer } from '../styles'

const FORM = 'yourpret-signup'
const FREQUENCY = 'frequency'

export const formDefinition = () => ({
  id: FORM,
  fields: [{ id: FREQUENCY, type: [], value: '1' }]
})

const SignUp = ({ configureDropDown, getOptions }) => {
  return (
    <YourPretSignUpContainer>
      <Row>
        <Field>
          <Dropdown
            {...configureDropDown(FREQUENCY, {
              options: getOptions(FREQUENCY)
            })}
          />
        </Field>
      </Row>
    </YourPretSignUpContainer>
  )
}

SignUp.formDefinition = formDefinition

SignUp.propTypes = {
  getOptions: PropTypes.func.isRequired,
  configureField: PropTypes.func.isRequired,
  configureDropDown: PropTypes.func.isRequired
}

export default SignUp
