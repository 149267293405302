import ContactUs from './contact-us'
import SignUp from './yourpret-signup'
import CardAddress from './card-address'
import Feedback from './feedback'

const forms = {
  'contact-us': ContactUs,
  'yourpret-signup': SignUp,
  'card-address': CardAddress,
  feedback: Feedback
}

export default forms
