import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Field, Row } from '../styles'
import { Dropdown, Input, TextArea } from '@pretamanger/component-library'
import useInput from '../../../hooks/useInput'

const FORM = 'contact-us'
const FIRST_NAME = 'first-name'
const LAST_NAME = 'last-name'
const EMAIL = 'email-address'
const PHONE = 'telephone-number'
const HELP = 'help'
const COMMENTS = 'comments'
const WHICH_SHOP = 'which-shop'

const formDefinition = {
  id: FORM,
  fields: [
    { id: FIRST_NAME, mapTo: 'FirstName', type: ['isNotEmpty'] },
    { id: LAST_NAME, mapTo: 'Surname', type: ['isNotEmpty'] },
    { id: EMAIL, mapTo: 'EmailAddress', type: ['isNotEmpty', 'isEmail'] },
    { id: PHONE, mapTo: 'Telephone', type: ['isNotEmpty', 'isNumeric'] },
    { id: HELP, mapTo: 'FeedbackType', type: ['isNotEmpty'] },
    { id: COMMENTS, mapTo: 'Comments' },
    { id: WHICH_SHOP, mapTo: 'ShopNumber' }
  ]
}

const ContactUs = ({ formState, getOptions, getProps }) => {
  function createInitialState(id) {
    return {
      ...(formState[id] ? formState[id] : { id, dirty: false, isValid: true }),
      formId: FORM,
      form: formDefinition
    }
  }
  const {
    fieldState: firstName,
    setFieldState: setFirstName,
    bind: bindFirstName
  } = useInput(createInitialState(FIRST_NAME))
  const {
    fieldState: lastName,
    setFieldState: setLastName,
    bind: bindLastName
  } = useInput(createInitialState(LAST_NAME))
  const {
    fieldState: emailAddress,
    setFieldState: setEmailAddress,
    bind: bindEmailAddress
  } = useInput(createInitialState(EMAIL))
  const {
    fieldState: telephoneNumber,
    setFieldState: setTelephoneNumber,
    bind: bindTelephoneNumber
  } = useInput(createInitialState(PHONE))
  const {
    fieldState: help,
    setFieldState: setHelp,
    bind: bindHelp
  } = useInput(createInitialState(HELP))

  useEffect(() => {
    formState[FIRST_NAME] && setFirstName(formState[FIRST_NAME])
    formState[LAST_NAME] && setLastName(formState[LAST_NAME])
    formState[EMAIL] && setEmailAddress(formState[EMAIL])
    formState[PHONE] && setTelephoneNumber(formState[PHONE])
    formState[HELP] && setHelp(formState[HELP])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState])

  const configureField = (id, field, bind) => {
    return {
      ...getProps(id, field.dirty, field.isValid),
      ...bind
    }
  }

  return (
    <>
      <Row>
        <Field>
          <Input {...configureField(FIRST_NAME, firstName, bindFirstName)} />
        </Field>
        <Field>
          <Input {...configureField(LAST_NAME, lastName, bindLastName)} />
        </Field>
      </Row>
      <Row>
        <Field fullWidth>
          <Input {...configureField(EMAIL, emailAddress, bindEmailAddress)} />
        </Field>
      </Row>
      <Row>
        <Field>
          <Input
            {...configureField(PHONE, telephoneNumber, bindTelephoneNumber)}
          />
        </Field>
      </Row>
      <Row>
        <Field>
          <Dropdown
            {...configureField(HELP, help, bindHelp)}
            options={getOptions(HELP)}
          />
        </Field>
      </Row>
      <Row>
        <Field fullWidth>
          <TextArea {...getProps(COMMENTS)} />
        </Field>
      </Row>
      <Row>
        <Field>
          <Input {...getProps(WHICH_SHOP)} />
        </Field>
      </Row>
    </>
  )
}

ContactUs.formDefinition = formDefinition

ContactUs.propTypes = {
  formState: PropTypes.object.isRequired,
  getOptions: PropTypes.func.isRequired,
  getProps: PropTypes.func.isRequired
}

export default ContactUs
