import PropTypes from 'prop-types'
import { InfoBadge, TickBadge } from '../badge'
import { Wrapper, Body, IconContainer } from './styles'

const InfoPanel = ({ children, type, className }) => {
  const Badge = type && (type === 'info' ? InfoBadge : TickBadge)
  const dataTestId = type && (type === 'info' ? 'info-badge' : 'tick-badge')

  return (
    <Wrapper data-testid={dataTestId} className={className}>
      {Badge && (
        <IconContainer>
          <Badge />
        </IconContainer>
      )}
      <Body>{children}</Body>
    </Wrapper>
  )
}

InfoPanel.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  type: PropTypes.oneOf(['info', 'success'])
}

InfoPanel.defaultProps = {
  type: undefined
}

export default InfoPanel
