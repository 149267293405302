import PropTypes from 'prop-types'
import { useRouter } from 'next/router'
import { Dropdown, Heading, Input } from '@pretamanger/component-library'
import {
  Field,
  RowsGroup,
  CardAddressRow,
  CardAddressContainer
} from '../styles'
import {
  getCountryFromLocale,
  getLanguageFromLocale
} from '#src/common/lib/locale'
import { sortAlphabetically } from '#src/common/lib/sort'
import Translation from '#src/common/components/translation'
import { marketDetails } from '#constants'

const FORM = 'card-address'
const ADDRESS_LINE_ONE = 'address-line-one'
const ADDRESS_LINE_TWO = 'address-line-two'
const CITY = 'city'
const POST_CODE = 'post-code'
const COUNTRY = 'country'
const STATE_OR_PROVINCE = 'state-or-province'

export const formDefinition = locale => ({
  id: FORM,
  fields: [
    {
      id: ADDRESS_LINE_ONE,
      type: ['isNotEmpty'],
      htmlValidation: { maxLength: 150 }
    },
    {
      id: ADDRESS_LINE_TWO,
      type: ['isNotEmpty'],
      htmlValidation: { maxLength: 150 }
    },
    { id: CITY, type: ['isNotEmpty'], htmlValidation: { maxLength: 50 } },
    { id: POST_CODE, type: ['isNotEmpty', `isPostCode:${COUNTRY}`] },
    ...(locale === marketDetails.US.locale
      ? [
          {
            id: STATE_OR_PROVINCE,
            type: ['isNotEmpty'],
            htmlValidation: { maxLength: 50 }
          }
        ]
      : []),
    {
      id: COUNTRY,
      type: [],
      value: getCountryFromLocale(locale),
      dependentField: POST_CODE
    }
  ]
})

const CardAddress = ({ configureField, configureDropDown, getOptions }) => {
  const { locale } = useRouter()

  let Options = getOptions(COUNTRY)
  if (locale === marketDetails.FR.locale) {
    Options = getOptions(COUNTRY).filter(item => item.id !== 'CA')
  }
  return (
    <CardAddressContainer>
      <fieldset>
        <CardAddressRow>
          <legend>
            <Heading className='mb-1' level='h3' styleOverride='headingXs'>
              <Translation id='checkout.page.payment.card-address' />
            </Heading>
          </legend>
        </CardAddressRow>
        <CardAddressRow>
          <Field>
            <Input {...configureField(ADDRESS_LINE_ONE)} />
          </Field>
        </CardAddressRow>
        <CardAddressRow>
          <Field>
            <Input {...configureField(ADDRESS_LINE_TWO)} />
          </Field>
        </CardAddressRow>
        <RowsGroup>
          <CardAddressRow className='md:mr-2'>
            <Field>
              <Input {...configureField(CITY)} />
            </Field>
          </CardAddressRow>
          <CardAddressRow className='md:ml-2'>
            <Field>
              <Input {...configureField(POST_CODE)} />
            </Field>
          </CardAddressRow>
        </RowsGroup>
        {locale === marketDetails.US.locale && (
          <CardAddressRow>
            <Field>
              <Input {...configureField(STATE_OR_PROVINCE)} />
            </Field>
          </CardAddressRow>
        )}
        <CardAddressRow>
          <Field>
            <Dropdown
              {...configureDropDown(COUNTRY, {
                options: sortAlphabetically(
                  Options,
                  'label',
                  getLanguageFromLocale(locale)
                )
              })}
            />
          </Field>
        </CardAddressRow>
      </fieldset>
    </CardAddressContainer>
  )
}

CardAddress.formDefinition = formDefinition

CardAddress.propTypes = {
  getOptions: PropTypes.func.isRequired,
  configureField: PropTypes.func.isRequired,
  configureDropDown: PropTypes.func.isRequired
}

export default CardAddress
