import { getLogger } from '#src/api/logger-with-context'
import { useState, useEffect } from 'react'

const useScript = (scriptProps, callback, loading) => {
  const log = getLogger()
  const [state, setState] = useState({
    loaded: false,
    error: false
  })

  useEffect(() => {
    if (!loading) {
      const script = document.createElement('script')
      script.setAttribute('src', scriptProps.src)
      script.setAttribute('crossorigin', scriptProps.crossorigin)
      script.setAttribute('async', scriptProps.async)

      const onScriptLoad = () => setState({ loaded: true, error: false })
      const onScriptError = () => setState({ loaded: true, error: true })

      script.addEventListener('load', onScriptLoad)
      script.addEventListener('error', onScriptError)
      document.body.appendChild(script)

      return () => {
        script.removeEventListener('load', onScriptLoad)
        script.removeEventListener('error', onScriptError)
        document.body.removeChild(script)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scriptProps.src, loading])

  useEffect(() => {
    if (!state.loaded) return
    /**
     * We must not rerender the page after the Adyen drop-in component is mounted.
     * See: https://docs.adyen.com/checkout/drop-in-web/
     */
    log.info(
      'CheckoutPage re-rendered after Adyen drop-in component was mounted.'
    )
    callback()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.loaded])

  return state
}

export default useScript
