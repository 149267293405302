export const sortAlphabetically = (
  arr: any[],
  key: string,
  lang: string,
  descending: boolean = false
) => {
  const arrayToSort = [...arr]
  const compareFn = new Intl.Collator(lang).compare
  const direction = descending ? -1 : 1
  arrayToSort.sort((a, b) => {
    return compareFn(a[key], b[key]) * direction
  })
  return arrayToSort
}
